<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- background START -->
      <img class="header-bg" src="@/assets/images/header-bg@3x.png" />
      <!-- background END -->

      <!-- logo START -->
      <div class="logo logo-centered">
        <!-- <router-link :to="'/'"> -->
          <img width="120" height="27" src="@/assets/images/logo-syreta-full-white@3x.png" />
        <!-- </router-link> -->
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
export default {
  name: 'app-navigation',
  data() {
    return {
    };
  },
};
</script>
